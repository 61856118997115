import { Component, OnInit } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import {
  SetFiltersFromUrlQueryParams,
  SetUrlQueryParamsFromState
} from '@mg-platform/core/core-data-access'
import { IUser, UserAccountState } from '@mg-platform/users/users-data-access'
import { Store } from '@ngxs/store'
import { isEmpty } from 'lodash'
import { filter, map } from 'rxjs'
import { ThemeModeService } from '@mg-platform/core/core-util'
import { TrackingService } from '@mg-platform/core/core-feature'
import packageJson from '../../../../package.json'

@Component({
  selector: 'mg-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private store: Store,
    private themeModeService: ThemeModeService,
    private trackingService: TrackingService
  ) {}

  ngOnInit(): void {
    console.log(`Marcket Genie Version: ${packageJson.version}`)

    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        map(() => {
          return this.router.routerState.root.snapshot.queryParams
        })
      )
      .subscribe((params) => {
        const currentUser = this.store.selectSnapshot<IUser>(UserAccountState.currentUser)
        this.trackingService.sendEvent({
          event: 'page_view',
          extraProps: {
            referralType: currentUser?.referralType ?? 'Not Login'
          }
        })
        if (!isEmpty(params) && params['filters']) {
          this.store.dispatch(new SetFiltersFromUrlQueryParams(params['filters']))
        } else {
          this.store.dispatch(new SetUrlQueryParamsFromState())
        }
      })
  }
}
